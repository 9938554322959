<template>
  <div class="lg:h-[103px]">
    <div
        style="z-index: 999"
        class="fixed top-0 w-full flex items-center justify-around bg-white border lg:h-[103px] text-main-gray"
    >
      <NuxtLink to="/">
        <img
            width="151"
            height="56"
            src=""
            alt=""
            sizes="sm:100vw"
        />
      </NuxtLink>
      <ItemShoppingCart/>
    </div>
  </div>
</template>